import React, {useContext, useCallback, useState, useEffect} from 'react';
import styled from 'styled-components';
import {Context} from '../../../src/AppContext';
import {Button, Row, Col} from 'antd';
import StaticImage from '../../../src/Components/StaticImage';
import {navigate} from 'gatsby';
import ProductItem from '../../../src/Components/ProductItem';
import BlogItem from '../../../src/Components/BlogItem';
import Carousel from './BannerCarousel';
import PromoZoneTopSection from './PromoZoneTopSection';
import PromoZoneBottomSection from './PromoZoneBottomSection';
import useDimension from '../../../src/hooks/use-dimension';
const appConfig = require('../../../src/data.json');
const slugify = require('uslug');

const intro_items = [
  {
    icon: '/images/home-service-01.png',
    title: '系統化的一條龍服務',
    subtitle: '',
  },
  {
    icon: '/images/home-service-02.png',
    title: '專業親切的線上諮詢',
    subtitle: '',
  },
  {
    icon: '/images/home-service-03.png',
    title: '商品品質保證',
    subtitle: '',
  },
  {
    icon: '/images/home-service-04.png',
    title: '多元的付款方式',
    subtitle: '',
  },
];

const social_icons = [
  {
    image: 'home-social-ig.jpeg',
    link: appConfig.socialMedia.instagram.link,
  },
  {
    image: 'home-social-fb.jpeg',
    link: appConfig.socialMedia.facebook.link,
  },
];

export default function LandingPage(props) {
  const app = useContext(Context);
  const {promoItems} = app.state;
  const [products, setProducts] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const {rwd} = useDimension();

  const getProducts = useCallback(async () => {
    try {
      let resp = await app.actions.getProducts();
      setProducts(
        resp.filter((p) => app.state.productNames.indexOf(p.name) > -1),
      );
    } catch (err) {
      console.warn(err);
    }
  }, [app.actions, app.state.productNames]);

  const getBlogs = useCallback(async () => {
    try {
      let resp = await app.actions.getBlogs({
        query: {
          $and: [
            {
              label: {$not: {$regex: 'FAQ'}},
            },
            {
              label: {$not: {$regex: 'draft'}},
            },
          ],
        },
        sorting: ['-created'],
      });
      setBlogs(resp);
    } catch (ex) {
      console.warn(ex);
    }
  }, [app.actions]);

  useEffect(() => {
    (async () => {
      app.actions.setLoading(true);
      await Promise.all([getProducts(), getBlogs()]);
      app.actions.setLoading(false);
    })();
  }, [app.actions, getProducts, getBlogs]);

  return (
    <Wrapper>
      <div className="banner-container">
        <Carousel imagesArray={promoItems.filter((x) => x.type === 'banner')} />
      </div>
      <div className="center-content">
        <div className={'sub-promote-banner-wrapper'}>
          <PromoZoneTopSection
            promoZones={promoItems.filter((x) => x.type === 'top_zone')}
          />
        </div>

        <div className="row-section">
          <p className="title">熱門商品</p>
          <p className="subtitle">BSET SELLER </p>
          <Row
            gutter={[appConfig.productGridGutter, appConfig.productGridGutter]}
            style={{alignSelf: 'stretch'}}>
            {products
              .filter((p) => p.tag === 'HOT')
              .slice(0, 8)
              .map((p, idx) => {
                return (
                  <Col xs={12} md={8} lg={6} key={idx}>
                    <ProductItem
                      product={p}
                      onClick={() => {
                        navigate(`/product/${p.id}`);
                      }}
                    />
                  </Col>
                );
              })}
          </Row>
        </div>
      </div>

      <ServiceSection>
        <div className="center-content">
          <div className="row">
            {intro_items.map((item, idx) => (
              <ServiceItem key={idx} data-delighter>
                <img
                  src={item.icon}
                  height="100"
                  width="100"
                  alt="services-icon"
                />
                <p className="title">{item.title}</p>
              </ServiceItem>
            ))}
          </div>
        </div>
      </ServiceSection>

      <div className="center-content">
        <div className="row-section">
          <p className="title">最新商品</p>
          <p className="subtitle">NEW ARRIVALS</p>
          <Row
            gutter={[appConfig.productGridGutter, appConfig.productGridGutter]}
            style={{alignSelf: 'stretch'}}>
            {products
              .filter((p) => p.tag === 'NEW')
              .slice(0, 8)
              .map((p, idx) => {
                return (
                  <Col xs={12} md={8} lg={6} key={idx}>
                    <ProductItem
                      product={p}
                      onClick={() => {
                        navigate(`/product/${p.id}`);
                      }}
                    />
                  </Col>
                );
              })}
          </Row>
        </div>
      </div>

      <div style={{background: '#F4F4F4'}}>
        <div className="center-content">
          <div className="row-section" style={{padding: '30px 0'}}>
            <p className="title">活動專區</p>
            <p className="subtitle">PROMO ZONE</p>
            <PromoZoneBottomSection
              promoZones={promoItems.filter((x) => x.type === 'bottom_zone')}
            />
          </div>
        </div>
      </div>

      <div className="center-content">
        <div className="row-section">
          <p className="title">最新文章</p>
          <p className="subtitle">NEWS</p>
          <Row gutter={[20, 20]} style={{alignSelf: 'stretch'}}>
            {blogs.slice(0, rwd === 'pad' ? 2 : 3).map((record) => (
              <Col xs={24} sm={12} md={8} key={record.id}>
                <BlogItem record={record} extraStyle={{}} type="portrait" />
              </Col>
            ))}
          </Row>
        </div>
        <div className="row-section">
          <p className="title">社群連結</p>
          <p className="subtitle">SOCAIL MEDIA</p>
          <div className="social-icons-wrapper">
            {social_icons.map((icon, idx) => (
              <a
                className={'social-icon'}
                key={idx}
                href={`${icon.link}`}
                target="_blank"
                rel="noreferrer">
                <StaticImage filename={`${icon.image}`} />
              </a>
            ))}
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #fff;

  .banner-container {
    width: 100%;
    margin-bottom: 70px;

    @media screen and (max-width: ${appConfig.breakpoints.sm}px) {
      margin-bottom: 15px;
    }
  }

  .center-content {
    max-width: ${appConfig.maxContentWidth + 40}px;
    padding: 0px 20px;
    margin: 0 auto;

    @media screen and (max-width: ${appConfig.breakpoints.lg}px) {
      padding: 0px 15px;
    }

    & > .sub-promote-banner-wrapper {
      overflow: hidden;
      margin-bottom: 70px;
      @media screen and (max-width: ${appConfig.breakpoints.lg}px) {
        padding-top: 15px;
      }
    }

    & .row-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 70px 0;

      & > .title {
        text-align: center;
        font-size: 22px;
        letter-spacing: 1px;
        color: ${appConfig.colors.second};
        margin: 10px 0 0 0;
      }

      & > .subtitle {
        text-align: center;
        font-size: 12px;
        color: #9b9b9c;
        border-bottom: 0.5px solid #bdbdbd;
        padding: 0 32px 10px 32px;
        margin-bottom: 2em;
      }

      & .social-icons-wrapper {
        display: flex;
        width: 100%;
        justify-content: center;
        flex-wrap: wrap;

        & > .social-icon {
          flex: 1 1 30%;
        }

        & > .social-icon:nth-child(1) {
          margin: 0 20px 0 0;
        }

        @media screen and (max-width: ${appConfig.breakpoints.sm}px) {
          & > .social-icon {
            flex: 1 0 100%;
          }

          & > .social-icon:nth-child(1) {
            margin: 0 0 20px 0;
          }
        }
      }
    }
  }

  & .col {
    display: flex;
    flex-direction: column;
    margin-left: 10px;

    & > .title {
      letter-spacing: 2px;
      color: #5a5a5a;
      margin-bottom: 5px;
    }

    & > .title.delighter,
    & > .subtitle.delighter {
      transition: all 0.3s ease-out;
      transform: translateX(50%);
      opacity: 0;
    }

    & > .title.delighter.started,
    & > .subtitle.delighter.started {
      transform: none;
      opacity: 1;
    }

    & > .subtitle {
      color: #5a5a5a;
      font-size: 10px;
    }
  }
`;

const ServiceSection = styled.div`
  background-color: ${appConfig.colors.third};
  padding: 20px;
  margin-bottom: 70px;

  & .row {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
`;

const ServiceItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-basis: 25%;
  padding: 10px 0px;
  align-items: center;

  & > img {
    flex: 0;
  }

  & > .title {
    flex: 1;
    letter-spacing: 2px;
    color: ${appConfig.colors.main};
    margin-left: 20px;
    margin-bottom: 0;
    font-weight: 500;
  }

  &.delighter {
    transition: all 0.3s ease-out;
    transform: translateY(50%);
    opacity: 0;
  }

  &.delighter.started {
    transform: none;
    opacity: 1;
  }

  @media screen and (max-width: ${appConfig.breakpoints.lg}px) {
    flex-basis: 50%;

    & > img {
      width: 70px;
      height: 70px;
    }

    & > .title {
      margin-left: 5px;
    }
  }
`;
